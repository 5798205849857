import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import './table.scss'

type HeaderRenderFunction = () => string | JSX.Element | JSX.Element[]
type OrderChangeFunction = (column: string) => void
type RenderFunction = (row: any, column: TableColumnProps) => string | JSX.Element | JSX.Element[]
type RowClickFunction = (row: any) => void
type PageChangeFunction = (page: number) => void

interface TableColumnProps {
    align?: string
    canOrder?: boolean
    caption?: string
    headerRender?: HeaderRenderFunction
    name?: string
    key?: string
    render?: RenderFunction
    width?: number
}

interface TableProps {
    className?: string
    columns: TableColumnProps[]
    dataset: any[]
    onOrderChange?: OrderChangeFunction
    onRowClick?: RowClickFunction
    order?: { orderBy: string; sort: 'ASC' | 'DESC' }
    pagination?: { page: number; pageSize: number; count: number; onPageChange: PageChangeFunction }
    type?: 'simple-header'
}

export const Table = (props: TableProps) => {
    const { t } = useTranslation()

    const rowClickHandler = (event: MouseEvent, row: any) => {
        const target = event.target as HTMLElement

        if (target.className.indexOf(`table__body__column`) === -1 && target.className.indexOf(`table__body__row`) === -1) {
            return
        }

        if (props.onRowClick !== undefined) {
            props.onRowClick(row)
        }
    }

    return (
        <div className={`table${props.type === 'simple-header' ? ` table--simple-header` : ``}${props.className !== undefined ? ` ${props.className}` : ``}`}>
            <div className="table__header">
                {props.columns?.map((column, index) => (
                    <div
                        key={index}
                        className={`table__header__column table__header__column--align-${column.align}`}
                        style={{ flexBasis: column.width, maxWidth: column.width === undefined ? `unset` : column.width }}
                        onClick={(e) => {
                            if (!(e.target as HTMLDivElement).classList.contains(`table__header__column`)) {
                                return
                            }
                            return props.onOrderChange && column.canOrder !== false ? props.onOrderChange(column.name!) : null
                        }}
                    >
                        {column.caption !== undefined ? column.caption : null}
                        {column.headerRender !== undefined ? column.headerRender() : null}
                        <span className="order">
                            {column.canOrder && props.order?.orderBy !== column.name ? <img alt="Order ikon" src="/image/ic_sort.svg" /> : null}
                            {props.order?.orderBy === column.name ? <img alt="Order ikon" src={props.order?.sort === 'ASC' ? '/image/ic_sort_asc.svg' : '/image/ic_sort_desc.svg'} /> : null}
                        </span>
                    </div>
                ))}
            </div>
            <div className="table__body">
                {props.dataset?.length === 0 ? <div className="table__body__empty">Nincs megjeleníthető elem</div> : null}
                {props.dataset?.map((row, index) => (
                    <div key={index} className="table__body__row" style={{ cursor: props.onRowClick === undefined ? `unset` : `pointer` }} onClick={(event) => rowClickHandler(event, row)}>
                        {props.columns?.map((column, index) => (
                            <div key={index} className={`table__body__column table__body__column--align-${column.align}`} style={{ flexBasis: column.width, maxWidth: column.width === undefined ? `unset` : column.width }}>
                                {column.key !== undefined ? row[column.key] : null}
                                {column.render !== undefined ? column.render(row, column) : null}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="table__pagination">
                <span>
                    {t('table_total_title')} <b>{props.pagination?.count ?? 1}</b> {t('table_items_title')}
                </span>

                <div className="table__pagination__pager">
                    <button disabled={(props.pagination?.page ?? 1) === 1} onClick={(e) => props.pagination?.onPageChange(props.pagination?.page - 1)}>
                        <img src="/image/ic_left_icon.svg" />
                    </button>

                    {(() => {
                        const rows = []

                        const page = (props.pagination?.count ?? 1) / (props.pagination?.pageSize ?? 1)
                        //const mod = ((props.pagination?.count ?? 1) % (props.pagination?.pageSize ?? 1) > 0 ? 1 : 0)

                        for (let i = 0; i < page; i++) {
                            rows.push(
                                <button className={(props.pagination?.page ?? 1) === i + 1 ? 'selected' : ''} onClick={(e) => props.pagination?.onPageChange(i + 1)}>
                                    {i + 1}
                                </button>
                            )
                        }

                        return rows
                    })()}

                    <button disabled={(props.pagination?.page ?? 1) >= (props.pagination?.count ?? 1) / (props.pagination?.pageSize ?? 1)} onClick={(e) => props.pagination?.onPageChange(props.pagination?.page + 1)}>
                        <img src="/image/ic_right_icon.svg" />
                    </button>
                </div>
            </div>
        </div>
    )
}
